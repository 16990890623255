import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`The advent of smartphones and the Internet has brought together a variety of communities and like-minded individuals. This has resulted in the creation of many different subcultures and the emergence of corresponding language patterns. By observing the linguistic diversity of Twitter users, we aim to analyze the language patterns of different groups of people and communities through a mobile keyboard next-word prediction model.`}</p>
    </blockquote>
    <p>{`In a collaborative research project as part of `}<a parentName="p" {...{
        "href": "https://cs.utdallas.edu/utd-acm-research-spring2021/"
      }}>{`ACM Research`}</a>{`, my team analyzed language trends with a mobile keyboard next-word prediction model. Our team was deemed the winner of the research competition.  `}</p>
    <p>{`We trained a neural network using TensorFlow on a large corpus of tweets (from Sentiment140), and then refined it on various datasets of tweets from various twitter subgroups.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/acm-research/subgroup-models.png",
        "alt": "subgroup models"
      }}></img></p>
    <p>{`We created a data pipeline with the tf.Data api to improve training performance and because our dataset could not fit in memory.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/acm-research/model-data-format.png",
        "alt": "model data format"
      }}></img></p>
    <p>{`We created a 2-layer bidirectional LSTM model to predict the next word.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/acm-research/model-structure.png",
        "alt": "model structure"
      }}></img></p>
    <p>{`Then, we used hyperparameter optimization to determine the optimal values for the structure of the model.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/acm-research/hparam.png",
        "alt": "hparam"
      }}></img></p>
    <h2>{`Web Demo`}</h2>
    <p>{`You can try out our models via the `}<a parentName="p" {...{
        "href": "https://mobilekeyboard.web.app/"
      }}>{`Web Demo`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      